<template>
  <div :title="srcValue">
    {{ srcValue }}
  </div>
</template>

<script lang="ts" setup>
import { vgBodyCellProps } from "@olc/vue-grid";

const props = defineProps(vgBodyCellProps);

const { getGridCellValue } = useUtils();

const srcValue = computed(() => getGridCellValue(props.ctx));
</script>
