import { addDays, format, isSameDay, subDays } from "date-fns";

const holidays = {
  "01/01": "Den obnovy samostatného českého státu",
  "01/05": "Svátek práce",
  "08/05": "Den vítězství",
  "05/07": "Den slovanských věrozvěstů Cyrila a Metoděje",
  "06/07": "Den upálení mistra Jana Husa",
  "28/09": "Den české státnosti",
  "28/10": "Den vzniku samostatného československého státu",
  "17/11": "Den boje za svobodu a demokracii",
  "24/12": "Štědrý den",
  "25/12": "1. svátek vánoční",
  "26/12": "2. svátek vánoční"
};

const holidayImages: { [key in keyof typeof holidays]?: string } = {
  "01/01": "/holidays/s0101.webp"
};

export default function useHolidays () {
  function getEasterSunday (year: number): Date {
    const a = year % 19;
    const b = year % 4;
    const c = year % 7;
    const m = 24;
    const n = 5;
    const d = (19 * a + m) % 30;
    const e = (n + 2 * b + 4 * c + 6 * d) % 7;
    const u = d + e - 9;

    let day: number;
    let month: number;

    if (u === 25 && d === 28 && e === 6 && a > 10) {
      day = 18;
      month = 4;
    } else if (u >= 1 && u <= 25) {
      day = u;
      month = 4;
    } else if (u > 25) {
      day = u - 7;
      month = 4;
    } else {
      day = 22 + d + e;
      month = 3;
    }

    return new Date(year, month - 1, day);
  }

  function getEasterMonday (year: number): Date {
    return addDays(getEasterSunday(year), 1);
  }

  function getEasterFriday (year: number): Date {
    return subDays(getEasterSunday(year), 2);
  }

  function getHoliday (date: Date): string | undefined {
    const easterMonday = getEasterMonday(date.getFullYear());
    const easterFriday = getEasterFriday(date.getFullYear());

    if (isSameDay(date, easterMonday)) {
      return "Velikonoční pondělí";
    } else if (isSameDay(date, easterFriday)) {
      return "Velký pátek";
    } else {
      return holidays[format(date, "dd/MM") as keyof typeof holidays];
    }
  }

  function isHoliday (date: Date): boolean {
    return getHoliday(date) !== undefined;
  }

  function getHolidayImage (date: Date): {url: string;name: string;} | undefined {
    const holiDay = getHoliday(date);
    if (holiDay) {
      const url = holidayImages[format(date, "dd/MM") as keyof typeof holidays];
      if (url) {
        return { url, name: holiDay };
      }
    }
  }

  return { getHoliday, isHoliday, getHolidayImage };
}
