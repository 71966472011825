<template>
  <div :title="formatPlan(planArray)" class="plan-cell">
    <span v-for="(plan, index) in planArray" :key="plan" :class="plan == 'True' ? 'plan-cell-true' : 'plan-cell-false'">
      {{ plan == 'True' ? index + 1 : plan }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { vgBodyCellProps } from "@olc/vue-grid";

const props = defineProps(vgBodyCellProps);

const planArray = computed(() => {
  return props.ctx.getValue()?.split(" ");
});

const { formatPlan } = useFormat();
</script>

<style scoped>
.plan-cell {
  display: flex;
}

.plan-cell span {
  font-size: 13px;
  width: 18px;
  min-width: 18px;
  text-align: center;
}

</style>
