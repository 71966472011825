import type { InjectionKey, Ref, ComputedRef, WritableComputedRef } from "vue";
import type { components } from "~/types/api-spec";
import type { NemoTableConfig } from "~/components/nemo/NemoTable.vue";

type FormDetailCtx<D> = {
  /** formDataOriginal - updated on form save */
  data: D;
  /** formData - could hold unvalidated edit data */
  liveData?: D;
  /** Refresh form with data from the server */
  refresh?: () => void;
}

export const SYM_FORM_BASE_CTX = Symbol("SYM_FORM_BASE_CTX") as InjectionKey<FormBaseCtx>;

// ##################### Forms #####################
type FormDetailLocationCtx = FormDetailCtx<ComputedRef<Partial<components["schemas"]["Location"]>>>;
export const SYM_FORM_DETAIL_LOCATION = Symbol("SYM_FORM_DETAIL_LOCATION") as InjectionKey<FormDetailLocationCtx>;

type FormDetailEventCtx = FormDetailCtx<ComputedRef<components["schemas"]["EventDetail"]>>;
export const SYM_FORM_DETAIL_EVENT = Symbol("SYM_FORM_DETAIL_EVENT") as InjectionKey<FormDetailEventCtx>;

type FormDetailCenterCtx = FormDetailCtx<ComputedRef<components["schemas"]["Center"]>>;
export const SYM_FORM_DETAIL_CENTER = Symbol("SYM_FORM_DETAIL_CENTER") as InjectionKey<FormDetailCenterCtx>;

type FormDetailOfferCtx = FormDetailCtx<ComputedRef<components["schemas"]["OfferDetail"]>> & {
  saveCounter: Ref<number>;
};
export const SYM_FORM_DETAIL_OFFER = Symbol("SYM_FORM_DETAIL_OFFER") as InjectionKey<FormDetailOfferCtx>;

// ##################### Other #####################
export const SYM_LOCATION_DIFFICULTY_MONTH = Symbol("SYM_LOCATION_DIFFICULTY_MONTH") as InjectionKey<Ref<number>>;

type DispatchersType = components["schemas"]["Dispatcher"];
export const SYM_SELECTED_DISPATCHERS = Symbol("SYM_SELECTED_DISPATCHERS") as InjectionKey<Ref<DispatchersType[]>>;
export const SYM_ROUTE_MESSAGE_ID = Symbol("SYM_ROUTE_MESSAGE_ID") as InjectionKey<Ref<string>>;

export const SYM_IS_WIP_OFFER = Symbol("SYM_IS_WIP_OFFER") as InjectionKey<() => Ref<boolean>>;

export const SYM_LOCATION_RECALCULATE = Symbol("SYM_LOCATION_RECALCULATE") as InjectionKey<WritableComputedRef<string>>;

export const SYM_EVENT_TASK_ROW = Symbol("SYM_EVENT_TASK_ROW") as InjectionKey<ComputedRef<components["schemas"]["EventTask"]>>;

export const INVOICE_RECORD_GRID_ID = "invoice_records";

export const SYM_NEMO_TABLE_CONFIG = Symbol("SYM_NEMO_TABLE_CONFIG") as InjectionKey<Ref<NemoTableConfig>>;
