<template>
  <TooltipDynamic @show="onShow">
    <NuxtLinkIf
      :to="url"
      :target="getTarget"
      @click.stop
    >
      <slot />
    </NuxtLinkIf>
    <template #content>
      <props.config.contentComponent :tooltip-data="tooltipData" v-bind="config.props" />
    </template>
  </TooltipDynamic>
</template>

<script setup lang="ts">
const props = defineProps<{
  url?: string,
  target?: string,
  config: TooltipConfig,
}>();

const emit = defineEmits<{
  show: []
}>();

const tooltipData = ref<object | undefined>(props.config.data);

const onShow = async () => {
  if (!props.config.dataGetter) {
    return;
  }
  tooltipData.value = await props.config.dataGetter();
  emit("show");
};

const getTarget = computed(() => {
  if (props.target) {
    return props.target;
  }
  return "_self";
});
</script>
