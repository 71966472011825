<template>
  <Body class="app">
    <v-app class="position-relative">
      <v-navigation-drawer
        v-if="authStore.isLoggedIn"
        color="primaryExtraDark"
        rail
        permanent
        class="sideNav"
      >
        <HolidayImage />
        <v-list class="mx-auto text-center ma-10">
          <v-btn icon size="42" variant="text" :to="localePath({name: 'profile'})">
            <v-avatar size="42">
              <span class="text-h6">{{ initials }}</span>
            </v-avatar>
          </v-btn>
        </v-list>

        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-face-agent" title="Dispečink" :to="localePath({name: 'dispecink'})" />
          <v-list-item prepend-icon="mdi-account-wrench" title="Servis" :to="localePath({name: 'servis'})" />
          <v-list-item prepend-icon="mdi-currency-usd" title="Management" :to="localePath({name: 'obchod'})" />
          <v-list-item prepend-icon="mdi-tools" title="Management" :to="localePath({name: 'sprava'})" />
          <v-list-item prepend-icon="mdi-map" title="Mapa" :to="localePath({name: 'mapa'})" />
          <v-list-item prepend-icon="mdi-eye" title="Služba" :to="localePath({name: 'sluzba'})" />
          <v-list-group class="submenu level-1">
            <template #activator="{ props }">
              <v-list-item
                v-bind="props"
                class="parent"
                prepend-icon="mdi-menu"
              />
            </template>
            <v-list v-model:opened="open">
              <template v-for="(menuItem, index) in menu">
                <v-list-item
                  v-if="!menuItem?.children?.length && !menuItem?.tabs?.length"
                  :key="index"
                  :title="menuItem.title"
                  :value="menuItem.title"
                  :to="menuItem.to"
                />
                <v-list-group
                  v-if="menuItem?.children?.length"
                  :key="index"
                  :title="menuItem.title"
                  :to="menuItem.to"
                  class="submenu level-2"
                  :value="menuItem.title"
                >
                  <template #activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      class="parent"
                    />
                  </template>
                  <v-list-item
                    v-for="(subItem, subIndex) in menuItem.children"
                    :key="subIndex"
                    :title="subItem.title"
                    :to="subItem.to"
                  />
                </v-list-group>
                <v-list-group
                  v-if="menuItem?.tabs?.length"
                  :key="index"
                  :title="menuItem.title"
                  :to="menuItem.to"
                  class="submenu level-2"
                  :value="menuItem.title"
                >
                  <template #activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      class="parent"
                    />
                  </template>
                  <v-list-item
                    v-for="(subItem, subIndex) in menuItem.tabs"
                    :key="subIndex"
                    :title="subItem.title"
                    :to="subItem.to"
                  />
                </v-list-group>
              </template>
            </v-list>
          </v-list-group>
          <v-list-item prepend-icon="mdi-exit-to-app" title="Odhlásit se" @click="authStore.logout" />
        </v-list>
        <div class="themeSwitch__component">
          <theme-switcher class="vertical" />
        </div>

        <template #append>
          <div class="logo d-flex justify-center mb-4 mt-4">
            <neklima-logo class="logo" @click="navigateTo(localePath({'name': 'index'}))" />
          </div>
        </template>
      </v-navigation-drawer>

      <v-main class="main-container">
        <slot />
      </v-main>
      <TooltipGlobal />
    </v-app>
    <!-- @vue-ignore -->
    <NuxtNotifications
      position="top right"
      width="500"
      :dangerously-set-inner-html="true"
      pause-on-hover
    />
  </Body>
</template>

<script setup lang="ts">
// @ts-ignore
import NeklimaLogo from "../assets/svg/logo-vertical-white.svg";

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Neklid` : "Neklid";
  }
});

const authStore = useAuthStore();
const localePath = useLocalePath();
const route = useRoute();
const { fullMenu } = useMenu();
const open = ref<string[]>([]);

const initials = computed(() => {
  const name = `${authStore.sessionData?.first_name} ${authStore.sessionData?.last_name}`.trim() || authStore.sessionData?.username;
  if (!name) {
    return "XX";
  }
  const [first, last] = name.split(" ");
  return `${first?.[0] ?? ""}${last?.[0] ?? ""}`.toUpperCase();
});

const menu = computed<MenuItem[]>(() => {
  const meniItem = fullMenu.find(item => item.to === route.matched[0]?.path);
  return meniItem?.children || [];
});

watchEffect(() => {
  open.value = menu.value.map(item => item.title);
});
</script>
