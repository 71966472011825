<template>
  <VueDatePicker
    v-model="selectedDate"
    locale="cs"
    :format="format"
    class="vg-filter-custom-datepicker"
    :teleport="true"
    :select-text="t('common.select')"
    :cancel-text="t('common.cancel')"
    :dark="theme.global.current.value.dark"
    :time-picker="filterType === 'time'"
    :enable-time-picker="filterType !== 'date'"
    :multi-calendars="{ solo: true }"
    range
  >
    <template #action-extra>
      <div class="vg-filter-custom-datepicker-action-row-extra">
        <div>
          <span>Filtrovat podle:</span>
          <div class="vg-filter-custom-datepicker-action-row-extra-toggles">
            <button
              type="button"
              role="checkbox"
              class="vg-filter-custom-datepicker-toggle border-s-md border-e-md"
              :class="selectedClass('day')"
              :disabled="filterType === 'time'"
              @click="toggleFilter('day')"
            >
              DEN.
            </button>
            <button
              type="button"
              role="checkbox"
              class="vg-filter-custom-datepicker-toggle border-e-md"
              :class="selectedClass('month')"
              :disabled="filterType === 'time'"
              @click="toggleFilter('month')"
            >
              MĚSÍC
            </button>
            <button
              type="button"
              role="checkbox"
              class="vg-filter-custom-datepicker-toggle border-e-md"
              :class="selectedClass('year')"
              :disabled="filterType === 'time'"
              @click="toggleFilter('year')"
            >
              ROK
            </button>
            <button
              type="button"
              role="checkbox"
              style="margin-right: -1.625px; z-index: 5;"
              class="vg-filter-custom-datepicker-toggle"
              :class="selectedClass('hour')"
              :disabled="filterType === 'date'"
              @click="toggleFilter('hour')"
            >
              HOD
            </button>
            <span style="pointer-events: none">:</span>
            <button
              type="button"
              role="checkbox"
              style="margin-left: -1.625px; z-index: 5;"
              class="vg-filter-custom-datepicker-toggle border-e-md"
              :class="selectedClass('minute')"
              :disabled="filterType === 'date'"
              @click="toggleFilter('minute')"
            >
              MIN
            </button>
          </div>
        </div>
      </div>
    </template>
  </VueDatePicker>
</template>

<script lang="ts" setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import { computed } from "vue";
import type { VgColumnFilterDateProps } from "@olc/vue-grid";
import { useTheme } from "vuetify";
import { isObject, clone } from "lodash-es";

const props = defineProps<VgColumnFilterDateProps>();

const { DATETIME_FORMAT, DATE_FORMAT, TIME_FORMAT } = useFormat();
const { t } = useI18n();
const theme = useTheme();

const filterType = computed<"date" | "time" | "datetime">(() => {
  const fieldType = (props.filterCustomData as EditCellColumnFilterCustomData)?.formField?.type;
  if (fieldType && ["date", "time", "datetime"].includes(fieldType)) {
    return fieldType as "date" | "time" | "datetime";
  }
  return "datetime";
});

const format = computed(() => {
  switch (filterType.value) {
  case "date":
    return DATE_FORMAT;
  case "time":
    return TIME_FORMAT;
  case "datetime":
    return DATETIME_FORMAT;
  }
  return DATETIME_FORMAT;
});

type SelectedFilters = {
  day: boolean;
  month: boolean;
  year: boolean;
  hour: boolean;
  minute: boolean;
};

const FILTER_DEFAULTS: Record<"date" | "time" | "datetime", SelectedFilters> = {
  date: {
    day: true,
    month: true,
    year: true,
    hour: false,
    minute: false
  },
  time: {
    day: false,
    month: false,
    year: false,
    hour: true,
    minute: true
  },
  datetime: {
    day: true,
    month: true,
    year: true,
    hour: false,
    minute: false
  }
};

const selectedFilters = ref<SelectedFilters>(props.outputs[props.index]?.filters ? clone(props.outputs[props.index].filters) : FILTER_DEFAULTS[filterType.value]);
const selectedDate = ref(props.outputs[props.index]?.value ?? null);

watchImmediate(() => props.outputs[props.index], () => {
  if (!props.outputs[props.index]) {
    selectedDate.value = null;
  } else if (!(isObject(props.outputs[props.index]) && ("value" in props.outputs[props.index]))) {
    // eslint-disable-next-line vue/no-mutating-props
    props.outputs[props.index] = null;
  }
});

watch(selectedDate, (value) => {
  // eslint-disable-next-line vue/no-mutating-props
  props.outputs[props.index] = {
    value: clone(toRaw(value)),
    filters: clone(toRaw(selectedFilters.value))
  };
});

function isSelected (key: keyof SelectedFilters) {
  return selectedFilters.value[key];
}

function selectedClass (key: keyof SelectedFilters) {
  return isSelected(key) ? "vg-filter-custom-datepicker-toggle__active" : "";
}

function toggleFilter (key: keyof typeof selectedFilters.value) {
  selectedFilters.value[key] = !selectedFilters.value[key];
}
</script>
