<template>
  <v-text-field
    v-model="color"
    :label="label"
    color="primary"
    variant="outlined"
    append-inner-icon="mdi-palette"
    :active="color !== undefined && color !== null && color !== ''"
  >
    <template #prepend-inner>
      <v-icon icon="mdi-circle" :color="color" />
    </template>
  </v-text-field>
</template>

<script setup lang="ts">
defineProps<{
  label?: string
}>();
const color = defineModel<string>();
</script>
