import { type StoreType, useFormDataController } from "./useFormDataController";
import type { components } from "~/types/api-spec";

type Address = components["schemas"]["Address"];
type AddressDetail = components["schemas"]["SimpleAddressDetail"];
type User = components["schemas"]["User"];
type Dispatcher = components["schemas"]["Dispatcher"];
type Center = components["schemas"]["SimpleCenter"];
type Currency = components["schemas"]["Currency"];
type Technician = components["schemas"]["SimpleTechnician"];
type Contact = components["schemas"]["Contact"];
type Region = components["schemas"]["Region"];
type Partner = components["schemas"]["SimplePartner"];
type Location = components["schemas"]["SimpleLocation"];
type PartnerLocations = components["schemas"]["PartnerLocationList"];
type Event = components["schemas"]["SimpleEventSerializerWithType"];
type BusinessCase = components["schemas"]["SimpleBusinessCase"];
type Demand = components["schemas"]["SimpleDemand"];
type Messages = components["schemas"]["Messages"];
type Stock = components["schemas"]["Stock"];
type Offer = components["schemas"]["SimpleOffer"];
type PriceList = components["schemas"]["SimplePriceList"];
type OfferItem = components["schemas"]["SimpleOfferItem"];
type DemandReport = components["schemas"]["SimpleDemandReport"];
type Documentation = components["schemas"]["Documentation"];
type PartnerOutput = components["schemas"]["PartnerOutput"];
type Report = components["schemas"]["SimpleReport"];
type EventTypeTask = components["schemas"]["EventTypeTask"];
type VehicleDials = components["schemas"]["VehicleDials"];
type CoolantType = components["schemas"] ["CoolantType"];
type Invoice = components["schemas"] ["SimpleInvoice"];
type Record_ = components["schemas"] ["SimplestRecord"];
type ReportDetail = components["schemas"] ["SimplestReportDetail"];
type EconomicCentre = components["schemas"] ["EconomicCentre"];

export function useCommonFormData () {
  const { createDataController } = useFormDataController();

  const addressStore: StoreType<Address[]> = {};
  const addressDetailStore: StoreType<AddressDetail[]> = {};
  const partnerStore: StoreType<Partner[]> = {};
  const locationStore: StoreType<Location[]> = {};
  const partnerLocationsStore: StoreType<PartnerLocations[]> = {};
  const eventStore: StoreType<Event[]> = {};
  const userStore: StoreType<User[]> = {};
  const dispatcherStore: StoreType<Dispatcher[]> = {};
  const centerStore: StoreType<Center[]> = {};
  const currencyStore: StoreType<Currency[]> = {};
  const technicianStore: StoreType<Technician[]> = {};
  const contactStore: StoreType<Contact[]> = {};
  const regionStore: StoreType<Region[]> = {};
  const businessCaseStore: StoreType<BusinessCase[]> = {};
  const demandStore: StoreType<Demand[]> = {};
  const messagesStore: StoreType<Messages[]> = {};
  const sparePartStockStore: StoreType<Stock[]> = {};
  const offerStore: StoreType<Offer[]> = {};
  const priceListStore: StoreType<PriceList[]> = {};
  const offerItemStore: StoreType<OfferItem[]> = {};
  const demandReportStore: StoreType<DemandReport[]> = {};
  const documentationStore: StoreType<Documentation[]> = {};
  const partnerOutputStore: StoreType<PartnerOutput[]> = {};
  const reportStore: StoreType<Report[]> = {};
  const eventTypeTaskStore: StoreType<EventTypeTask[]> = {};
  const serviceCenterStore: StoreType<Center> = {};
  const VehicleStore: StoreType<VehicleDials[]> = {};
  const CoolantTypeStore: StoreType<CoolantType[]> = {};
  const invoiceStore: StoreType<Invoice[]> = {};
  const recordStore: StoreType<Record_[]> = {};
  const reportDetailStore: StoreType<ReportDetail[]> = {};
  const materialStore: StoreType<Record<string, any>[]> = {};
  const economicCentreStore: StoreType<EconomicCentre[]> = {};

  const addresses = createDataController(addressStore, "/api/department/address/list/");
  const addressDetails = createDataController(addressDetailStore, "/api/department/address/detail/list/");
  const partners = createDataController(partnerStore, "/api/department/partner/list/");
  const locations = createDataController(locationStore, "/api/department/location/list/");
  const partnerLocations = createDataController(partnerLocationsStore, "/api/department/location/partner/list/");
  const users = createDataController(userStore, "/api/user/list/");
  const userDispatchers = createDataController(userStore, "/api/user/is-dispatcher-list/");
  const dispatchers = createDataController(dispatcherStore, "/api/user/dispatcher/list/");
  const centers = createDataController(centerStore, "/api/department/center/list/");
  const currencies = createDataController(currencyStore, "/api/department/currency/list/");
  const technicians = createDataController(technicianStore, "/api/department/technician/list/");
  const userTechnicians = createDataController(userStore, "/api/user/is-technician-list/");
  const contacts = createDataController(contactStore, "/api/department/contact/list/");
  const regions = createDataController(regionStore, "/api/department/region/list/");
  const businessCases = createDataController(businessCaseStore, "/api/department/partner/business-case-list/");
  const businessCasesForOfferApprove = createDataController(businessCaseStore, "/api/department/partner/business-case-offer-approve-list/");
  const demands = createDataController(demandStore, "/api/department/demand/list/");
  const messages = createDataController(messagesStore, "/api/department/message/all/");
  const events = createDataController(eventStore, "/api/department/event/list/");
  const sparePartStocks = createDataController(sparePartStockStore, "/api/department/part/spare/stock-list/");
  const offers = createDataController(offerStore, "/api/department/offer/list/");
  const priceLists = createDataController(priceListStore, "/api/department/price-list/list/");
  const offerItems = createDataController(offerItemStore, "/api/department/offer/item/list/");
  const offerItemsVariant = createDataController(offerItemStore, "/api/department/offer/item/variant/list/");
  const offerItemsVariantUnique = createDataController(offerItemStore, "/api/department/offer/item/variant/list/unique/");
  const demandReports = createDataController(demandReportStore, "/api/department/demand/report/list/");
  const documentations = createDataController(documentationStore, "/api/department/documentation/list/");
  const partnerOutputs = createDataController(partnerOutputStore, "/api/department/output/partner-output/list/");
  const reports = createDataController(reportStore, "/api/department/report/list/");
  const eventTypeTasks = createDataController(eventTypeTaskStore, "/api/department/task/event-type-task/list/");
  const serviceCenter = createDataController(serviceCenterStore, "/api/department/center/service/");
  const vehicles = createDataController(VehicleStore, "/api/service/dials/vehicle/");
  const coolantType = createDataController(CoolantTypeStore, "/api/administration/coolant-type/list/");
  const invoices = createDataController(invoiceStore, "/api/department/invoice/list/");
  const arrivalTimes = createDataController(priceListStore, "/api/department/price-list/list/arrival-time/");
  const unitTypes = createDataController(priceListStore, "/api/department/price-list/list/unit-type/");
  const records = createDataController(recordStore, "/api/department/record/list/");
  const reportDetails = createDataController(reportDetailStore, "/api/department/report/detail/list/");
  const material = createDataController(materialStore, "/api/department/event/{id}/material/");
  const economicCentre = createDataController(economicCentreStore, "/api/department/economic-centre/list/");

  return {
    addresses,
    addressDetails,
    partners,
    locations,
    partnerLocations,
    users,
    userDispatchers,
    dispatchers,
    centers,
    currencies,
    technicians,
    userTechnicians,
    contacts,
    regions,
    businessCases,
    businessCasesForOfferApprove,
    demands,
    messages,
    events,
    sparePartStocks,
    offers,
    priceLists,
    offerItems,
    offerItemsVariant,
    offerItemsVariantUnique,
    demandReports,
    documentations,
    partnerOutputs,
    reports,
    serviceCenter,
    eventTypeTasks,
    vehicles,
    coolantType,
    invoices,
    arrivalTimes,
    unitTypes,
    records,
    reportDetails,
    material,
    economicCentre
  };
}
