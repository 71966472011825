<template>
  <div>
    <ColorPickerInput
      v-if="!isUpdate"
      v-model="model"
      :label="options?.label"
      readonly
    />
    <ColorPicker
      v-else
      v-model="model"
      :label="options?.label"
      :rules="rulesComp"
      :class="classes"
      :placeholder="options?.placeholder"
      :autofocus="options?.autofocus"
      :messages="options?.messages"
      clearable
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  options?: FormFieldBaseOptionsRaw,
  isUpdate?: boolean,
}>();

const model = defineModel<any>();

const { getFieldClasses, getRulesWithRequired } = useForms();

const rulesComp = computed(() => getRulesWithRequired(props.options?.required, props.options?.rules));
const classes = computedAsync(async () => await getFieldClasses(rulesComp.value, model.value), []);
</script>
