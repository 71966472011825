<template>
  <v-input v-model="files">
    <v-field
      v-model="files"
      multiple
      :variant="writable ? 'outlined' : 'plain'"
      :clearable="writable"
    >
      <div class="d-flex flex-wrap w-100 ga-1" :class="writable ? 'pa-2' : ''">
        <v-chip
          v-for="file, i in files"
          :key="i"
          size="small"
          density="comfortable"
          label
          class="px-1"
          :closable="writable"
          @click:close="removeFile(i)"
        >
          <span class="pa-0">{{ formatFile(file) }}</span>
        </v-chip>
      </div>
    </v-field>
    <template v-if="files.length > 1" #append>
      <v-btn
        text="zip"
        icon="mdi-folder-zip-outline"
        size="small"
        density="compact"
        class="vg-grid-reset"
        title="Stáhnout jako zip"
      />
    </template>
  </v-input>
</template>

<script setup lang="ts">
defineProps<{
  writable?: boolean
}>();

const { formatFile } = useFormat();

const files = defineModel<(string | File)[]>({
  default: () => []
});

const emit = defineEmits<{
  "update:modelValue": [(string | File)[]]
}>();

const removeFile = (index: number) => {
  files.value.splice(index, 1);
  emit("update:modelValue", files.value);
};
</script>

<style>
.v-input--horizontal .v-input__append {
  margin-inline-start: auto;
}

.v-field--appended {
  --v-field-padding-end: 0;
}

.v-chip__content {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
