<template>
  <div class="vg-column-filter location-plan">
    <div>
      <label>1</label>
      <v-checkbox v-model="newValue[0]" class="small-checkbox" />
    </div>
    <div>
      <label>2</label>
      <v-checkbox v-model="newValue[1]" class="small-checkbox" />
    </div>
    <div>
      <label>3</label>
      <v-checkbox v-model="newValue[2]" class="small-checkbox" />
    </div>
    <div>
      <label>4</label>
      <v-checkbox v-model="newValue[3]" class="small-checkbox" />
    </div>
    <div>
      <label>5</label>
      <v-checkbox v-model="newValue[4]" class="small-checkbox" />
    </div>
    <div>
      <label>6</label>
      <v-checkbox v-model="newValue[5]" class="small-checkbox" />
    </div>
    <div>
      <label>7</label>
      <v-checkbox v-model="newValue[6]" class="small-checkbox" />
    </div>
    <div>
      <label>8</label>
      <v-checkbox v-model="newValue[7]" class="small-checkbox" />
    </div>
    <div>
      <label>9</label>
      <v-checkbox v-model="newValue[8]" class="small-checkbox" />
    </div>
    <div>
      <label>10</label>
      <v-checkbox v-model="newValue[9]" class="small-checkbox" />
    </div>
    <div>
      <label>11</label>
      <v-checkbox v-model="newValue[10]" class="small-checkbox" />
    </div>
    <div>
      <label>12</label>
      <v-checkbox v-model="newValue[11]" class="small-checkbox" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { type VgColumnFilterTextSimpleProps } from "@olc/vue-grid";

const props = defineProps<VgColumnFilterTextSimpleProps>();

const newValue = reactive(Array.isArray(props.outputs[props.index]) ? props.outputs[props.index] : Array(12).fill(false));

watch(newValue, (newValue: any[]) => {
  if (newValue.every((v: any) => v !== true)) {
    // eslint-disable-next-line vue/no-mutating-props
    props.outputs[props.index] = null;
  } else {
    // eslint-disable-next-line vue/no-mutating-props
    props.outputs[props.index] = newValue;
  }
});
</script>

<style scoped>
.vg-column-filter.location-plan {
  padding: 0 15px;
  gap: 5.5px;
}

.vg-column-filter.location-plan div {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 12.4px;
  max-width: 14.4px;
}

.small-checkbox {
  zoom: 60%;
}

</style>
