<template>
  <div class="w-100" title="">
    <FormFieldFiles
      v-model:hosted-files="hosted"
      v-model:local-files="local"
      :multiple="field.multiple"
      :is-update="true"
      :options="baseOptionsComputed"
      @update:hosted-files="(val: any) => field.onChange?.(val)"
      @update:local-files="(val: any) => field.onChange?.(val)"
    />
  </div>
</template>

<script setup lang="ts">
import { type GridCellContext, useGridContext } from "@olc/vue-grid";

const props = defineProps<{
  data: EditCellColumnFilterCustomDataRaw,
  ctx: GridCellContext<any>
}>();

const model = defineModel<string | null | undefined |(File | string)[]>("value", { required: true });

const { gridApi } = useGridContext();
const { extractBaseOptions, extractBaseOptionsAsync } = useForms();

const currentRow = computed(() => {
  if (props.data.useOriginalData) {
    return props.ctx.row.original;
  }
  return gridApi.updatedData.find((row: any) => row.id === props.ctx.row.id) ?? props.ctx.row.original;
});

const field = computed<FormFieldForRenderer & FormFieldFile>(() => {
  return {
    ...props.data.formField,
    ...props.data.formFieldBuilder?.({ ...currentRow.value }, { ...props.ctx.row.original }) ?? {},
    inputIcon: ""
  } as FormFieldForRenderer & FormFieldFile;
});

const baseOptionsComputed = computedAsync(() => extractBaseOptionsAsync(field.value), extractBaseOptions(field.value));

const hosted = computed<string[]>({
  get: () => {
    if (!model.value) {
      return [];
    }
    if (typeof model.value === "string") {
      return model.value.split("\n");
    }
    return model.value.filter(v => typeof v === "string") as string[];
  },
  set: (value: string[]) => {
    if (!value) {
      model.value = null;
      return;
    }
    let modelVal = model.value || [];
    if (typeof modelVal === "string") {
      modelVal = [];
    }
    model.value = [...value, ...modelVal.filter(v => typeof v !== "string") ?? []];
  }
});

const local = computed<File[]>({
  get: () => {
    if (!model.value) {
      return [];
    }
    if (typeof model.value === "string") {
      return [];
    }
    return model.value.filter(v => typeof v !== "string") as File[];
  },
  set: (value: File[]) => {
    if (!value) {
      model.value = null;
      return;
    }
    let modelVal = model.value || [];
    if (typeof modelVal === "string") {
      modelVal = modelVal.split("\n");
    }
    model.value = [...modelVal.filter(v => typeof v === "string") ?? [], ...value];
  }
});
</script>
