<template>
  <v-menu v-model="showPicker" min-width="auto" :close-on-content-click="false" :close-on-back="true">
    <template #activator="{ props }">
      <ColorPickerInput v-bind="{...props, ...$attrs}" v-model="color" :label="label" />
    </template>
    <v-color-picker
      v-model="color"
      v-click-outside="() => (showPicker = false)"
      show-swatches
    />
  </v-menu>
</template>

<script setup lang="ts">
defineOptions({
  inheritAttrs: false
});

defineProps<{
  label?: string
}>();
const color = defineModel<string>();

const showPicker = ref(false);
</script>
