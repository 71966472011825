import { defineStore } from "pinia";
import type { Component } from "vue";

export type TooltipOptions = {
  showDelay?: number;
  traverseDelay?: number;
};

export const TOOLTIP_OPTIONS_DEFAULTS = {
  showDelay: 400,
  traverseDelay: 150
};

export const SCROLLBAR_WIDTH = 20;
export const SIDE_MENU_WIDTH = 56;
export const EDGE_MARGIN = 8;

export const useTooltip = defineStore("global_tooltip", () => {
  const showDelay = ref(TOOLTIP_OPTIONS_DEFAULTS.showDelay);
  const traverseDelay = ref(TOOLTIP_OPTIONS_DEFAULTS.traverseDelay);

  const isActive = ref(false);
  const ttActivator = ref<HTMLDivElement | null>(null);
  const ttBody = ref<HTMLDivElement | null>(null);
  const ttContent = shallowRef<Component | null>(null);
  const ttContentProps = shallowRef<Record<string, any>>({});
  const traverseTimer = ref<number | null>(null);
  const tooltipTimer = ref<number | null>(null);
  const resizeObserver = new ResizeObserver(calculateTooltipPos);

  const resetComponent = ref(false);
  const contentEnterValid = ref(false);
  const activatorLeaveValid = ref(true);

  async function show () {
    clearTimer(traverseTimer);
    isActive.value = true;
    if (resetComponent.value) {
      resetComponent.value = false;
      const aux = ttContent.value;
      ttContent.value = null;
      await nextTick();
      ttContent.value = aux;
    } else {
      await nextTick();
    }
    calculateTooltipPos();
    if (ttBody.value) {
      resizeObserver.observe(ttBody.value);
    }
  }

  function hide () {
    isActive.value = false;
    resizeObserver.disconnect();
  }

  function clearTimer (timer: Ref<number | null>) {
    if (timer.value) {
      window.clearTimeout(timer.value);
      timer.value = null;
    }
  }

  function calculateTooltipPos () {
    if (ttBody.value === null || ttActivator.value === null) { return; }

    // Activator box
    const aBox = ttActivator.value.getBoundingClientRect();

    const contentWidth = ttBody.value.scrollWidth;
    const contentHeight = ttBody.value.scrollHeight;

    const fitsLeft = contentWidth + SIDE_MENU_WIDTH < aBox.left;
    const fitsRight = aBox.left + (aBox.width / 2) + contentWidth + SCROLLBAR_WIDTH < window.innerWidth;
    const fitsBelow = aBox.bottom + contentHeight + EDGE_MARGIN < window.innerHeight;
    const fitsAbove = aBox.top - contentHeight - EDGE_MARGIN >= 0;

    // Horizontal placement
    let bodyLeft;
    if (fitsRight) {
      bodyLeft = !fitsBelow
        ? (aBox.left + window.scrollX + Math.min(aBox.width, 150))
        : (aBox.left + window.scrollX + Math.min(aBox.width / 2, 150));
    } else if (!fitsBelow && fitsLeft) {
      bodyLeft = aBox.left + window.scrollX - contentWidth;
    } else {
      bodyLeft = Math.max(window.innerWidth + window.scrollX - SCROLLBAR_WIDTH - contentWidth, SCROLLBAR_WIDTH + window.scrollX);
    }
    ttBody.value.style.left = `${bodyLeft}px`;

    // Vertical placement
    let bodyTop = null;
    let bodyBottom = null;
    if (fitsBelow) {
      bodyTop = aBox.bottom + window.scrollY;
    } else if (fitsLeft || fitsRight) {
      bodyTop = Math.max(window.innerHeight + window.scrollY - EDGE_MARGIN - contentHeight, EDGE_MARGIN + window.scrollY);
    } else if (fitsAbove) {
      bodyBottom = window.innerHeight - aBox.top - window.scrollY;
    } else if (aBox.bottom - window.scrollY < window.innerHeight / 2) {
      bodyTop = aBox.bottom + window.scrollY;
      bodyBottom = EDGE_MARGIN - window.scrollY;
    } else {
      bodyTop = EDGE_MARGIN + window.scrollY;
      bodyBottom = window.innerHeight - aBox.top - window.scrollY;
    }
    ttBody.value.style.top = bodyTop ? `${bodyTop}px` : "";
    ttBody.value.style.bottom = bodyBottom ? `${bodyBottom}px` : "";

    // console.log(window.innerWidth, window.scrollX, contentWidth, window.innerWidth * 0.9);
    // console.log(`Fit: left: ${fitsLeft}, right: ${fitsRight}, below: ${fitsBelow}, above: ${fitsAbove}`);
    // console.log(`Final position: left: ${bodyLeft}, top: ${bodyTop}, bottom: ${bodyBottom}`);
  }

  const registerBody = (content: HTMLDivElement) => {
    ttBody.value = content;
  };

  const onActivatorUnmounted = () => {
    clearTimer(tooltipTimer);
    hide();
  };

  const activatorEnter = (activator: HTMLDivElement, content: Component, contentProps?: object, onShow?: CallableFunction, options?: TooltipOptions) => {
    if (!activatorLeaveValid.value) {
      return;
    }
    if (tooltipTimer.value) {
      clearTimer(tooltipTimer);
    }
    tooltipTimer.value = window.setTimeout(() => {
      ttActivator.value = activator;
      if (content === ttContent.value) {
        resetComponent.value = true;
      }
      ttContent.value = content;
      ttContentProps.value = contentProps || {};
      if (options) {
        showDelay.value = options.showDelay ?? TOOLTIP_OPTIONS_DEFAULTS.showDelay;
        traverseDelay.value = options.traverseDelay ?? TOOLTIP_OPTIONS_DEFAULTS.traverseDelay;
      }
      show();
      if (onShow) { onShow(); }
    }, showDelay.value);
  };

  function shadedByTtContent (leaveEvent: MouseEvent) {
    let containsContent = false;
    const elements = document.elementsFromPoint(leaveEvent.clientX, leaveEvent.clientY);
    for (const el of elements) {
      if (el.classList.contains("tooltip-content")) {
        containsContent = true;
      } else if (el.classList.contains("tooltip-activator")) {
        return containsContent;
      }
    }
    return false;
  }

  const activatorLeave = (event: MouseEvent, onHide?: CallableFunction) => {
    if (shadedByTtContent(event)) {
      activatorLeaveValid.value = false;
      return;
    }
    activatorLeaveValid.value = true;
    if (!tooltipTimer.value) {
      return;
    }
    clearTimer(tooltipTimer);
    if (traverseTimer.value) {
      return;
    }
    // Delay because activator's mouseleave is called before mouseenter/over on the content
    traverseTimer.value = window.setTimeout(function () {
      hide();
      if (onHide) { onHide(); }
    }, traverseDelay.value);
  };

  const contentEnter = (event: MouseEvent) => {
    if (shadedByTtContent(event)) {
      contentEnterValid.value = false;
      return;
    }
    contentEnterValid.value = true;
    clearTimer(traverseTimer);
  };

  const contentLeave = () => {
    if (!contentEnterValid.value) {
      return;
    }
    // Delay because content's mouseleave is called before mouseenter/over on the activator
    traverseTimer.value = window.setTimeout(function () {
      hide();
    }, traverseDelay.value);
  };

  return { shadedByTtContent, registerBody, onActivatorUnmounted, isActive, activatorEnter, activatorLeave, contentEnter, contentLeave, ttContent, ttContentProps };
});
