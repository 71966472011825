<template>
  <v-checkbox
    v-model="model"
    color="primary"
    density="compact"
    class="flex-grow-0"
    :rules="isUpdate ? rulesComp : []"
    :class="isUpdate ? classes : ''"
    :disabled="!(isUpdate ?? false)"
    :messages="options?.messages"
    :indeterminate="indeterminate && (model === null || model === undefined)"
  >
    <template v-if="options?.label" #label>
      <NuxtLinkIf target="_blank" :to="options?.link">
        <slot>{{ options?.label }}</slot>
      </NuxtLinkIf>
    </template>
  </v-checkbox>
</template>

<script setup lang="ts">
const props = defineProps<{
  options?: FormFieldBaseOptionsRaw,
  isUpdate?: boolean,
  indeterminate?: boolean,
}>();

const model = defineModel<boolean | null | undefined>();

const { getFieldClasses, getRulesWithRequired } = useForms();

const rulesComp = computed(() => getRulesWithRequired(props.options?.required, props.options?.rules));
const classes = computedAsync(async () => await getFieldClasses(rulesComp.value, model.value), []);
</script>
