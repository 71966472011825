<template>
  <div>
    <v-field
      v-if="!isUpdate"
      color="primary"
      :label="options.label"
      variant="outlined"
      readonly
      :active="model !== undefined && model !== null && model !== ''"
      :model-value="readOnlyValue"
    >
      <div class="form-field-readonly-container">
        <div class="form-field-readonly-container--filler-div">
          <TooltipDataV1
            v-if="options.tooltip"
            :url="options.link"
            :config="options.tooltip!"
          >
            <slot>{{ readOnlyValue }}</slot>
          </TooltipDataV1>
          <NuxtLink v-else-if="options.link" target="_blank" :to="options.link">
            <slot>{{ readOnlyValue }}</slot>
          </NuxtLink>
          <span v-else>{{ readOnlyValue }}</span>
        </div>
      </div>
    </v-field>
    <v-text-field
      v-else
      v-model="model"
      :type="type"
      color="primary"
      :label="options.label"
      variant="outlined"
      :rules="rulesComp"
      :class="classes"
      :placeholder="options.placeholder"
      :autofocus="options.autofocus"
      :messages="options.messages"
      clearable
    />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    options?: FormFieldBaseOptionsRaw;
    type?: string;
    isUpdate?: boolean;
  }>(),
  {
    options: () => ({}),
    type: "text",
    isUpdate: false
  }
);

const model = defineModel<any>();

const { getFieldClasses, getRulesWithRequired } = useForms();

const rulesComp = computed(() => getRulesWithRequired(props.options?.required, props.options?.rules));
const classes = computedAsync(async () => await getFieldClasses(rulesComp.value, model.value), []);

const readOnlyValue = computed(() => {
  if (props.options.readonlyFormatter) {
    return props.options.readonlyFormatter(model.value);
  }
  return model.value;
});

const formCtx = inject(SYM_FORM_DETAIL_LOCATION);

const index = computed(() => { return props.options.index; });

const isActive = computed(() => {
  if (index.value === undefined) {
    return false;
  } else if ((formCtx?.data.value?.plan ?? "_ _ _ _ _ _ _ _ _ _ _ _").split(" ")[index.value] === "_") {
    return false;
  } else {
    return true;
  }
});

const color = computed(() => {
  if (isActive.value) {
    return "rgb(125, 179, 53)";
  } else {
    return "gray";
  }
});

const borderWidth = computed(() => {
  if (isActive.value) {
    return "3px";
  } else {
    return "1px";
  }
});
</script>

<style itemscope>
.gridplan .item div .v-text-field .v-input__control .v-field .v-field__outline {
  --v-field-border-opacity: 0.6;
  --v-field-border-width: v-bind(borderWidth);
  color: v-bind(color);
}
</style>
