<template>
  <div>
    <v-field
      v-if="!isUpdate"
      color="primary"
      :label="options.label"
      variant="outlined"
      readonly
      :active="model !== undefined && model !== null && model !== ''"
      :model-value="readOnlyValue"
    >
      <div class="form-field-readonly-container">
        <div class="form-field-readonly-container--filler-div">
          <TooltipDataV1
            v-if="options.tooltip"
            :url="options.link"
            :config="options.tooltip!"
          >
            <slot>{{ readOnlyValue }}</slot>
          </TooltipDataV1>
          <NuxtLink v-else-if="options.link" target="_blank" :to="options.link">
            <slot>{{ readOnlyValue }}</slot>
          </NuxtLink>
          <span v-else>{{ readOnlyValue }}</span>
        </div>
      </div>
    </v-field>
    <v-text-field
      v-else
      v-model="model"
      :type="type"
      color="primary"
      :label="options.label"
      variant="outlined"
      :rules="rulesComp"
      :class="classes"
      :placeholder="options.placeholder"
      :autofocus="options.autofocus"
      :messages="options.messages"
      clearable
    />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  options?: FormFieldBaseOptionsRaw,
  type?: FormFieldType,
  isUpdate?: boolean,
}>(), {
  options: () => ({}),
  type: "text",
  isUpdate: false
});

const model = defineModel<any>({
  set: (v) => {
    if (props.type === "number") {
      return parseFloat(v) ?? null;
    }
    return v;
  }
});

const { getFieldClasses, getRulesWithRequired } = useForms();
const rulesComp = computed(() => getRulesWithRequired(props.options?.required, props.options?.rules));
const classes = computedAsync(async () => await getFieldClasses(rulesComp.value, model.value), []);

const { formatNumber } = useFormat();

const readOnlyValue = computed(() => {
  if (props.options.readonlyFormatter) {
    return props.options.readonlyFormatter(model.value);
  }
  if (props.type === "number") {
    return formatNumber(model.value);
  }
  return model.value;
});
</script>
