<template>
  <v-skeleton-loader v-show="loading" :type="`paragraph@${nParagraphs}`" />
  <div
    v-show="!loading"
    class="editor-default-template editor--container"
    :class="!editing ? 'editor--container-readonly' : ''"
    @click.self="onContentClick"
  >
    <div class="text-editor-header-wrapper" @click="onContentClick">
      <h5 v-if="placeholder" class="text-editor-header">
        {{ placeholder }}
      </h5>
    </div>
    <div v-show="editing">
      <TinyMCE
        v-if="initEditor"
        ref="editor"
        v-model="editorContent"
        api-key="ox8yw4kckhnw7ey3yihonn98bwlt8gs2y3cw1h5uqyquf9un"
        :init="{
          plugins: [ 'advlist', 'autolink', 'lists', 'link', 'charmap',
                     'anchor', 'searchreplace',
                     'help'],
          toolbar: 'undo redo | blocks | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          removed_menuitems: 'newdocument print visualaid codeformat',
          placeholder: placeholder,
          readonly: true, // start in readonly mode
          height: height,
          content_style: 'body { font-size: 13px; margin: 0.5rem; }'
        }"
        @init="onEditorInit"
      />
    </div>
    <div v-show="!editing" @click="onContentClick">
      <div
        :class="'editor-content' + (cssClass ? ' ' + cssClass : '')"
        v-html="editorContent"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import TinyMCE from "@tinymce/tinymce-vue";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";

interface TextEditorProps {
  canEdit?: boolean;
  placeholder?: string;
  theme?: EditorThemeName;
  height?: number | string;
  initOnClick?: boolean;
  cssClass?: string;
}

const props = withDefaults(defineProps<TextEditorProps>(), {
  canEdit: false,
  placeholder: "",
  theme: "default",
  height: "1000px",
  cssClass: ""

});

const loading = ref(false);
const editorContent = defineModel<string | null>();
const editor = ref<typeof TinyMCE | null>(null);
// Never set this back to false from true
const initEditor = ref(false);
const editorInitialized = ref(false);
const editing = computed(() => editorInitialized.value && props.canEdit);

const nParagraphs = computed(() => Math.ceil((typeof props.height === "string" ? parseInt(props.height, 10) : props.height) / 150));

async function onEditorInit (event: any, tinymceEditor: typeof TinyMCE) {
  // eslint-disable-next-line no-console
  console.log("TinyMCE INIT");
  tinymceEditor.mode.set(props.canEdit ? "design" : "readonly");
  editorInitialized.value = true;
  loading.value = false;
  if (props.initOnClick) {
    await nextTick();
    getTinymce().activeEditor.focus();
  }
}

watchImmediate(() => props.canEdit, (value) => {
  if (editor.value) {
    const editorInstance = editor.value.getEditor();
    editorInstance.mode.set(value ? "design" : "readonly");
  } else if (value && !props.initOnClick) {
    loading.value = true;
    initEditor.value = true;
  }
});

function onContentClick () {
  if (props.canEdit && !initEditor.value) {
    loading.value = true;
    initEditor.value = true;
  }
}
</script>
