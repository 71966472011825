<template>
  <NuxtLoadingIndicator />
  <n-config-provider :theme="$vuetify.theme.current.dark ? darkTheme : null" :theme-overrides="$vuetify.theme.current.dark ? darkThemeOverrides : lightThemeOverrides" :locale="csCZ" :date-locale="dateCsCZ">
    <n-loading-bar-provider>
      <n-message-provider>
        <n-notification-provider>
          <n-modal-provider>
            <n-dialog-provider>
              <NuxtLayout>
                <NuxtPage />
              </NuxtLayout>
            </n-dialog-provider>
          </n-modal-provider>
        </n-notification-provider>
      </n-message-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>

<script setup lang="ts">
import "@olc/vue-loader/dist/style.css";
import "@vuepic/vue-datepicker/dist/main.css";
import { darkTheme, NConfigProvider, NLoadingBarProvider, NMessageProvider, NNotificationProvider, NModalProvider, NDialogProvider, type GlobalThemeOverrides, csCZ, dateCsCZ } from "naive-ui";
import { useTheme } from "vuetify";

const computedThemes = useTheme().computedThemes;

const lightThemeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: computedThemes.value.light.colors.primary,
    primaryColorHover: computedThemes.value.light.colors.link
  }
};

const darkThemeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: computedThemes.value.dark.colors.primary,
    primaryColorHover: computedThemes.value.dark.colors.link
  }
};

useHead({
  link: [
    { rel: "icon", type: "image/svg+xml", href: "/favicon.svg" }
  ]
});
</script>
