import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_42RIGIx6e8 from "/app/node_modules/@nuxt/image/dist/runtime/plugin.js";
import plugin_ZGw8LlWmeq from "/app/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import capitalize_3EKYYxxdRq from "/app/src/plugins/capitalize.ts";
import constants_HrGtQj3JvV from "/app/src/plugins/constants.ts";
import sentry_client_KAXFuL2wum from "/app/src/plugins/sentry.client.ts";
import useEmitter_tTWW85dJvZ from "/app/src/plugins/useEmitter.ts";
import vuetify_F4zXYcNIwC from "/app/src/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_42RIGIx6e8,
  plugin_ZGw8LlWmeq,
  chunk_reload_client_UciE0i6zes,
  capitalize_3EKYYxxdRq,
  constants_HrGtQj3JvV,
  sentry_client_KAXFuL2wum,
  useEmitter_tTWW85dJvZ,
  vuetify_F4zXYcNIwC
]