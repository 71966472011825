<template>
  <FileDisplayField
    v-if="files.length"
    v-model="files"
    :writable="false"
  />
</template>
<script setup lang="ts">
import { vgBodyCellProps } from "@olc/vue-grid";

const props = defineProps(vgBodyCellProps);

const files = computed<(string | File)[]>(() => {
  const value = props.ctx.getValue();
  if (!value) {
    return [];
  }
  if (Array.isArray(value)) {
    return value as (string | File)[];
  }
  if (typeof value === "string") {
    return value.split("\n");
  }
  return [];
});

</script>
