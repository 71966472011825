<template>
  <v-autocomplete
    v-if="!combo"
    v-model="modelComp"
    return-object
    no-filter
    type="select"
    :items="state.items"
    :item-title="itemTitle"
    :item-value="itemValue"
    :default-items="defaultItems"
    color="primary"
    variant="outlined"
    clearable
    placeholder="Vyberte..."
    :rules="getRulesWithRequiredComputed"
    :class="getFieldClassesComputed"
    @update:focused="onOpen"
    @update:search="filterKeyUp"
  >
    <template v-if="state.items.length || state.loading || state.isLoadingNext" #append-item>
      <div v-intersect="onListEnd">
        <v-progress-linear
          v-if="state.loading || state.isLoadingNext"
          indeterminate
          color="primary"
        />
      </div>
    </template>
  </v-autocomplete>
  <v-combobox
    v-else
    v-model="modelComp"
    return-object
    no-filter
    type="select"
    :items="state.items"
    :item-title="itemTitle"
    :item-value="itemValue"
    :default-items="defaultItems"
    color="primary"
    variant="outlined"
    clearable
    placeholder="Vyberte..."
    :rules="getRulesWithRequiredComputed"
    :class="getFieldClassesComputed"
    @update:focused="onOpen"
    @update:search="filterKeyUp"
  >
    <template v-if="state.items.length" #append-item>
      <div v-intersect="onListEnd">
        <v-progress-linear
          v-if="state.loading || state.isLoadingNext"
          indeterminate
          color="primary"
        />
      </div>
    </template>
  </v-combobox>
</template>

<script setup lang="ts">
import { isEmpty, isObject } from "lodash-es";

interface State {
  items: Record<string, any>[],
  loading: boolean,
  lastFetchedAt?: number,
  timeout: number | null,
  isLoadingNext: boolean,
  nextUrl: string | null
}

const props = withDefaults(defineProps<{
  dataUrl?: APIPath | string | null,
  defaultItems?: Record<string, any>[],
  itemTitle: string,
  itemValue: string,
  lazy?: boolean,
  required?: boolean | MaybeRefOrGetter<boolean>,
  rules?: SimpleInputRule[],
  defaultGetter?: ((data: any) => any | Promise<any>),
  noObjects?: boolean,
  combo?: boolean
}>(), {
  dataUrl: undefined,
  defaultItems: () => [],
  lazy: false,
  required: false,
  rules: () => [],
  defaultGetter: undefined
});

type PrimitiveValue = string | number | boolean;
type ModelItemType = Record<string, any> | (Record<string, any> | PrimitiveValue)[] | PrimitiveValue | null | undefined;
type ModelCompItemType = Record<string, any> | Record<string, any>[] | null | undefined;

const model = defineModel<ModelItemType>();
// VueGrid can't save reactive objects and v-autocomplete returns Proxy<object>
const modelComp = computed<ModelCompItemType>({
  get: () => {
    if (model.value === null || model.value === undefined) {
      return model.value;
    }
    if (Array.isArray(model.value)) {
      return model.value.map((item) => {
        if (!isObject(item)) {
          return {
            [props.itemValue]: item,
            [props.itemTitle]: item
          };
        }
        return item;
      });
    }
    if (!isObject(model.value)) {
      return {
        [props.itemValue]: model.value,
        [props.itemTitle]: model.value
      };
    }
    return model.value;
  },
  set: (val: ModelCompItemType) => {
    if (props.noObjects) {
      if (Array.isArray(val)) {
        val = val.map(item => item[props.itemTitle]);
      } else if (isObject(val)) {
        val = val ? val[props.itemTitle] : null;
      }
    }
    model.value = toRaw(val);
  }
});

function modelAsArrayOfValues () {
  if (Array.isArray(modelComp.value)) {
    return modelComp.value.map(item => item[props.itemValue]);
  }
  if (isObject(modelComp.value)) {
    return [modelComp.value[props.itemValue]];
  }
  return modelComp.value ? [modelComp.value] : [];
}

const emit = defineEmits<{
  "fetch": [State["items"]]
}>();

const { cachedCall } = useApi();
const { getFieldClasses, getRulesWithRequired } = useForms();

const getRulesWithRequiredComputed = computed(() => getRulesWithRequired(props.required, props.rules));
const getFieldClassesComputed = computedAsync(async () => await getFieldClasses(getRulesWithRequiredComputed.value, modelComp.value), []);

const state = reactive<State>({
  items: [],
  loading: false,
  timeout: null,
  isLoadingNext: false,
  nextUrl: null
});

watch(() => props.dataUrl, async () => {
  // Only use default items if they contain the selected item, otherwise selection would be lost
  if (!modelComp.value || props.defaultItems.find(item => modelAsArrayOfValues().includes(item[props.itemValue]))) {
    state.items = props.defaultItems;
  }

  if (!props.lazy) {
    await asyncFind("");
    if (isEmpty(modelComp.value) && props.defaultGetter && state.items.length > 0) {
      const defaultVal = await props.defaultGetter(state.items);
      if (defaultVal !== undefined && defaultVal !== null) {
        modelComp.value = defaultVal;
      }
    }
  }
}, { immediate: true });

watch(() => state.items, (cur, old) => {
  emit("fetch", cur);
  const finder = (item: typeof state.items[0]) => modelAsArrayOfValues().includes(item[props.itemValue]);
  if (modelComp.value && old.find(finder) && !cur.find(finder)) {
    modelComp.value = null;
  }
});

function doNotSearch (query: string) {
  return !props.combo && modelComp.value && !Array.isArray(modelComp.value) && query === modelComp.value[props.itemTitle];
}

function filterKeyUp (query: string) {
  if (state.timeout) {
    clearTimeout(state.timeout);
    state.timeout = null;
  }

  if (doNotSearch(query)) {
    return;
  }
  state.timeout = window.setTimeout(
    () => {
      if (doNotSearch(query)) {
        return;
      }
      asyncFind(query);
    }, 500);
}

function onOpen (opened: boolean) {
  if (opened && !state.loading && state.items.length <= props.defaultItems.length) {
    asyncFind("");
  }
}

function onListEnd (isIntersecting: boolean) {
  if (state.items.length && isIntersecting) {
    loadNext();
  }
}

async function loadNext () {
  if (!state.nextUrl) {
    return;
  }

  // console.log("loading next...");
  state.isLoadingNext = true;
  const { data, error } = await cachedCall(
    state.nextUrl as APIPath, "get" as APIPathMethod
  );

  if (error.value) {
    useNotifier().error("load");
    state.isLoadingNext = false;
    return;
  }

  state.items.push(...(data.value as PaginatedResponse<Record<string, any>>).results);
  state.nextUrl = (data.value as PaginatedResponse<Record<string, any>>).next;
  state.isLoadingNext = false;
}

async function asyncFind (search: string) {
  if (!props.dataUrl) {
    return;
  }

  state.loading = true;
  const tstamp = Date.now();
  state.lastFetchedAt = tstamp;
  const { data, error } = await cachedCall(
    props.dataUrl as APIPath,
    "get" as APIPathMethod,
    {
      query: {
        search
      }
    }
  );
  if (tstamp < state.lastFetchedAt) {
    return;
  }

  if (error.value) {
    useNotifier().error("load");
  } else if (data.value) {
    if (!(data.value as any)?.results) {
      // eslint-disable-next-line no-console
      console.warn("Paginated response expected, got", data.value);
    }
    state.items = [...props.defaultItems, ...(data.value as PaginatedResponse<Record<string, any>>)?.results ?? (Array.isArray(data.value) ? data.value : [])];
    state.nextUrl = (data.value as PaginatedResponse<Record<string, any>>).next;
  }
  state.loading = false;
}
</script>
