<template>
  <div class="form-field-textarea" :class="isUpdate ? classes : ''">
    <template v-if="withHtmlEditor">
      <TextEditor
        v-model="model"
        :can-edit="isUpdate"
        :init-on-click="!options.autofocus"
        :placeholder="options.label"
        :theme="theme"
        :height="height"
      />
      <div v-if="isUpdate">
        <v-text-field
          ref="textfield"
          v-model="model"
          style="display: none"
          :rules="rulesComp"
        />
        <div class="form-field-textarea-messages">
          <div class="form-field-textarea-message">
            {{ message }}
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <v-textarea
        v-model="model"
        :color="themes[theme || 'default'].color || ''"
        :class="themes[theme || 'default'].class || ''"
        :bg-color="themes[theme || 'default'].bgColor || ''"
        :label="options.label"
        :readonly="!isUpdate"
        :placeholder="placeholder"
        :rules="rulesComp"
        :messages="options.messages"
        :rows="rows || nRows"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  options?: FormFieldBaseOptionsRaw,
  isUpdate?: boolean,
  theme?: EditorThemeName,
  rows?: number,
  height?: number | string,
  withHtmlEditor?: boolean,
  placeholder?: string
}>(), {
  options: () => ({}),
  isUpdate: false,
  theme: "default",
  rows: undefined,
  height: "90px",
  placeholder: undefined
});

const model = defineModel<string | null>();

const { getFieldClasses, getRulesWithRequired } = useForms();

const rulesComp = computed(() => getRulesWithRequired(props.options?.required, props.options?.rules));
const classes = computedAsync(async () => await getFieldClasses(rulesComp.value, model.value), []);

const ROW_HEIGHT = 14;
const nRows = computed(() => Math.ceil((typeof props.height === "string" ? parseInt(props.height, 10) : props.height) / ROW_HEIGHT));

const message = computedAsync(async () => {
  for (const rule of rulesComp.value) {
    const ruleVal = await rule(model.value);
    if (ruleVal !== true) {
      return ruleVal as string;
    }
  }
  return props.options.messages;
}, "");

const themes: {
  [name in "default" | "blue" | "green" | "red" | "yellow"]: {
    color?: string,
    bgColor?: string,
    class?: string,
  }
} = {
  default: {
    color: "cyan"
  },
  blue: {
    color: "primary",
    bgColor: "statusInfoBg",
    class: "statusInfo"
  },
  green: {
    color: "primary",
    bgColor: "statusOkBg",
    class: "statusOk"
  },
  red: {
    bgColor: "statusErrorBg",
    class: "statusError"
  },
  yellow: {
    color: "cyan",
    bgColor: "statusWarningBg",
    class: "statusWarning"
  }
};
</script>
