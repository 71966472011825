<template>
  <div class="tooltip">
    <div ref="ttActivator" class="tooltip-activator" @mouseenter="activatorEnter" @mouseleave="activatorLeave">
      <slot>
        Hover me!
      </slot>
    </div>
    <Teleport to="body">
      <Transition
        name="tt-fade"
        @before-enter="setDuration"
        @after-enter="cleanUpDuration"
        @before-leave="setDuration"
        @after-leave="cleanUpDuration"
      >
        <div v-if="isActive" ref="ttContent" class="tooltip-content" @mouseover="contentEnter" @mouseleave="contentLeave">
          <slot name="content">
            Tooltip content
          </slot>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { EDGE_MARGIN, SCROLLBAR_WIDTH, SIDE_MENU_WIDTH, TOOLTIP_OPTIONS_DEFAULTS, type TooltipOptions } from "~/composables/tooltip/useTooltip";

const props = withDefaults(defineProps<{
  options?: TooltipOptions,
  isMuted?: boolean
}>(), {
  options: () => ({ ...TOOLTIP_OPTIONS_DEFAULTS })
});

const emit = defineEmits(["show", "hide"]);

const { shadedByTtContent } = useTooltip();

const isActive = defineModel<boolean>({ default: false });

const ttActivator = ref<HTMLDivElement | null>(null);
const ttContent = ref<HTMLDivElement | null>(null);
const traverseTimer = ref<number | null>(null);
const tooltipTimer = ref<number | null>(null);
const resizeObserver = new ResizeObserver(calculateTooltipPos);
const contentEnterValid = ref(false);
const activatorLeaveValid = ref(true);

watch(() => props.isMuted, (value) => {
  if (value) {
    clearTimer(tooltipTimer);
    clearTimer(traverseTimer);
    hide();
  }
});

onUnmounted(() => {
  clearTimer(tooltipTimer);
  hide();
});

const show = async () => {
  clearTimer(traverseTimer);
  isActive.value = true;
  await nextTick();
  calculateTooltipPos();
  if (ttContent.value) {
    resizeObserver.observe(ttContent.value);
  }
  emit("show");
};

const hide = () => {
  isActive.value = false;
  resizeObserver.disconnect();
  emit("hide");
};

function clearTimer (timer: Ref<number | null>) {
  if (timer.value) {
    window.clearTimeout(timer.value);
    timer.value = null;
  }
}

function calculateTooltipPos () {
  if (ttContent.value === null || ttActivator.value === null) { return; }

  // Activator box
  const aBox = ttActivator.value.getBoundingClientRect();

  const contentWidth = ttContent.value.scrollWidth;
  const contentHeight = ttContent.value.scrollHeight;

  const fitsLeft = contentWidth + SIDE_MENU_WIDTH < aBox.left;
  const fitsRight = aBox.left + (aBox.width / 2) + contentWidth + SCROLLBAR_WIDTH < window.innerWidth;
  const fitsBelow = aBox.bottom + contentHeight + EDGE_MARGIN < window.innerHeight;
  const fitsAbove = aBox.top - contentHeight - EDGE_MARGIN >= 0;

  // Horizontal placement
  let bodyLeft;
  if (fitsRight) {
    bodyLeft = !fitsBelow
      ? (aBox.left + window.scrollX + Math.min(aBox.width, 150))
      : (aBox.left + window.scrollX + Math.min(aBox.width / 2, 150));
  } else if (!fitsBelow && fitsLeft) {
    bodyLeft = aBox.left + window.scrollX - contentWidth;
  } else {
    bodyLeft = Math.max(window.innerWidth + window.scrollX - SCROLLBAR_WIDTH - contentWidth, SCROLLBAR_WIDTH + window.scrollX);
  }
  ttContent.value.style.left = `${bodyLeft}px`;

  // Vertical placement
  let bodyTop = null;
  let bodyBottom = null;
  if (fitsBelow) {
    bodyTop = aBox.bottom + window.scrollY;
  } else if (fitsLeft || fitsRight) {
    bodyTop = Math.max(window.innerHeight + window.scrollY - EDGE_MARGIN - contentHeight, EDGE_MARGIN + window.scrollY);
  } else if (fitsAbove) {
    bodyBottom = window.innerHeight - aBox.top - window.scrollY;
  } else if (aBox.bottom - window.scrollY < window.innerHeight / 2) {
    bodyTop = aBox.bottom + window.scrollY;
    bodyBottom = EDGE_MARGIN - window.scrollY;
  } else {
    bodyTop = EDGE_MARGIN + window.scrollY;
    bodyBottom = window.innerHeight - aBox.top - window.scrollY;
  }
  ttContent.value.style.top = bodyTop ? `${bodyTop}px` : "";
  ttContent.value.style.bottom = bodyBottom ? `${bodyBottom}px` : "";
}

const activatorEnter = () => {
  if (props.isMuted) {
    return;
  }
  tooltipTimer.value = window.setTimeout(show, props.options.showDelay);
};

const activatorLeave = (event: MouseEvent) => {
  if (props.isMuted) {
    return;
  }
  if (shadedByTtContent(event)) {
    activatorLeaveValid.value = false;
    return;
  }
  activatorLeaveValid.value = true;
  if (!tooltipTimer.value) {
    return;
  }
  clearTimer(tooltipTimer);
  if (traverseTimer.value) {
    return;
  }
  // Delay because activator's mouseleave is called before mouseenter/over on the content
  traverseTimer.value = window.setTimeout(function () {
    hide();
  }, props.options.traverseDelay);
};

const contentEnter = (event: MouseEvent) => {
  if (shadedByTtContent(event)) {
    contentEnterValid.value = false;
    return;
  }
  contentEnterValid.value = true;
  clearTimer(traverseTimer);
};

const contentLeave = () => {
  if (!contentEnterValid.value) {
    return;
  }
  // Delay because content's mouseleave is called before mouseenter/over on the activator
  traverseTimer.value = window.setTimeout(function () {
    hide();
  }, props.options.traverseDelay);
};

const setDuration = (el: Element) => {
  if (el instanceof HTMLElement) {
    el.style.transitionDuration = "200ms";
  }
};

const cleanUpDuration = (el: Element) => {
  if (el instanceof HTMLElement) {
    el.style.transitionDuration = "";
  }
};
</script>
