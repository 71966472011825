<template>
  <DatePicker
    v-model="model"
    type="date"
    :readonly="!isUpdate"
    :label="options?.label"
    :active="model !== undefined && model !== null && model !== ''"
    :rules="isUpdate ? rulesComp : []"
    :field-class="isUpdate ? classes : ''"
    :placeholder="options?.placeholder"
    :autofocus="options?.autofocus"
    :messages="options?.messages"
    :input-icon="options?.inputIcon"
    :default-to-end-of-day="options?.defaultToEndOfDay"
    class="form-field-date"
  />
</template>

<script setup lang="ts">
const props = defineProps<{
  options?: FormFieldBaseOptionsRaw,
  isUpdate?: boolean,
}>();

const model = defineModel<any>();

const { getFieldClasses, getRulesWithRequired } = useForms();

const rulesComp = computed(() => getRulesWithRequired(props.options?.required, props.options?.rules));
const classes = computedAsync(async () => await getFieldClasses(rulesComp.value, model.value), []);
</script>
