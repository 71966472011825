<template>
  <img v-if="holiday" class="holiday-image" :src="image(holiday.url)" :title="holiday.name">
</template>

<script setup lang="ts">
const { getHolidayImage } = useHolidays();
const holiday = ref(getHolidayImage(new Date()));
const image = useImage();
</script>

<style lang="css" scoped>

.holiday-image {
  width: 90%;
  height: auto;
  border-radius: 8px;
  display: block;
  margin: 5%;
}

</style>
