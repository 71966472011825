<template>
  <div :title="titleString">
    <v-text-field
      v-if="!isUpdate"
      color="primary"
      :label="options?.label"
      variant="outlined"
      class="plan-field"
      readonly
      active
      :value="planField"
    />
    <v-field
      v-else
      :label="options?.label"
      color="primary"
      variant="outlined"
      class="plan-field"
      :readonly="!Array.isArray(options?.readonly) && options?.readonly"
      active
    >
      <FormFieldPlanCheckbox
        v-for="(plan, index) in fields"
        :key="index"
        :value="plan"
        :index="index"
        :disabled="Array.isArray(options?.readonly) && options?.readonly[index]"
        @on-changed="onChanged"
      />
    </v-field>
  </div>
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue";
import FormFieldPlanCheckbox from "./FormFieldPlanCheckbox.vue";

defineProps<{
  options?: Omit<FormFieldBaseOptionsRaw, "readonly"> & { readonly?: boolean | boolean[] },
  isUpdate?: boolean,
}>();

const model = defineModel<string>();
const planField = computed(() => model.value ? model.value.split(" ").map((val, index) => val === "True" ? (index + 1).toString() : "_").join(" ") : "");

const fields = computed<boolean[]>(() => model.value ? model.value.split(" ").map(val => val !== "_") : Array.from({ length: 12 }, () => false));

function onChanged (updateObject: { index: number, value: boolean }) {
  model.value = fields.value.map((field, index) => index === updateObject.index ? updateObject.value : field).map(val => val ? "True" : "_").join(" ");
}

const titleString = computed(() => {
  if (!model.value) {
    return "";
  }
  let res = "";
  for (const [index, plan] of fields.value.entries()) {
    if (plan) {
      res += `${index + 1} `;
    } else {
      res += "_ ";
    }
  }
  return res.trim();
});
</script>
