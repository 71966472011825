<template>
  <div>
    <v-text-field
      v-if="!isUpdate"
      color="primary"
      :label="options?.label"
      variant="outlined"
      readonly
      :active="model !== undefined && model !== null && model !== ''"
      :value="model"
    >
      <template #append-inner>
        <v-btn :href="'mailto:' + model" icon="mdi-email-arrow-right" slim density="compact" />
      </template>
    </v-text-field>
    <v-text-field
      v-else
      v-model="model"
      color="primary"
      :label="options?.label"
      type="email"
      variant="outlined"
      :prepend-inner-icon="options?.inputIcon ?? 'mdi-email-arrow-right'"
      :rules="rulesComp"
      :class="classes"
      :placeholder="options?.placeholder || 'adresa@domena.com'"
      :autofocus="options?.autofocus"
      :messages="options?.messages"
      clearable
      @click:clear="clearToBlank"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  options?: FormFieldBaseOptionsRaw,
  isUpdate?: boolean,
}>();

const model = defineModel<any>();

const { validationRules } = useFormValidators();
const { getFieldClasses, getRulesWithRequired } = useForms();

const rulesComp = computed(() => getRulesWithRequired(props.options?.required, [validationRules.isEmail, ...props.options?.rules ?? []]));
const classes = computedAsync(async () => await getFieldClasses(rulesComp.value, model.value), []);

function clearToBlank () {
  model.value = "";
}
</script>
